export default {
  init() {
    // JavaScript to be fired on all pages

    
    $('.menu-item-has-children > a').addClass('test')
      .attr('data-toggle', 'dropdown')
      .attr('role','button')
      .attr('aria-haspopup', 'true')
      .attr('aria-expanded', 'false');

    $('.sub-menu').addClass('dropdown-menu');
    
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
